.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.border11{
  border-radius: 49% 51% 42% 58% / 53% 60% 40% 47%;
}

.border12{
  border-radius: 59% 41% 68% 32% / 24% 46% 54% 76%;
}

.yellow-dots {
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: radial-gradient(circle, #FDD835 15%, transparent 5%);
  background-size: 10px 10px;
  background-repeat: repeat;
  pointer-events: none;
}

.relative {
  position: relative;
}